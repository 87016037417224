import { SEO } from "@components/seo";
import { globalHistory as history } from '@reach/router';
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { isMobile } from 'react-device-detect';
import Cursor from "../components/Cursor/cursor";
import Section from "../components/_SectionTemplate";
import FeaturedWork from "../components/_pages/home/FeaturedWork";
import Layout from "../components/layout";
import "../styles/pages/_contact.scss";
import ContactForm from './../components/Contact/ContactForm';
import useLocoScroll from './../components/_hooks/useLocoScroll';

const ContactPage = (props) => {
  const { location } = history;
  useLocoScroll(true);
  const isContactPage = location.pathname.startsWith("/contact");

  return <Layout location={location}>
    {!isMobile && <Cursor />}
    {/* <Scroll callbacks={location} /> */}
    <SEO title="Contact - Send me a message" />

    <Container fluid className="contact" id="contact">
      <Row>
        <Col>
          <Section desc={`contact ${isContactPage ? "cp" : ""}`}>
            <div className="contact-inner" data-scroll-section data-scroll-target="#contact" data-scroll data-scroll-speed="1">
              {/* <StoriesBanner /> */}
              {/* <TonyeMarquee content="I'm super passionate about everything creative. Technology, music, fitness, art & culture." /> */}
              <h1 data-scroll data-scroll-speed="1">Send me a message</h1>
              <ContactForm />
              <div className="position-relative about-wrapper featured" data-scroll-target="#contact" data-scroll data-scroll-speed="1">
                <FeaturedWork />
              </div>
            </div>
          </Section>
        </Col>
      </Row>
    </Container>

  </Layout>
}

export default ContactPage
